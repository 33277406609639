.header {
  display: flex;
  padding: 32px 32px 14px;
  color: white;
  align-items: center;
  flex-direction: column;
}

.header .header-content {
  max-width: 100%;
  text-align: center;
  padding: 45px 0 10px;
}

@media only screen and (min-width: 960px) {
  .header .header-content {
    max-width: 600px;
    padding: 45px 15px 10px;
  }
}

.header .header-content h1 {
  font-size: 3rem;
  text-align: center;
  line-height: 3rem;
  margin: 2rem auto 3.2rem;
  padding-top: 2rem;
}

.header .header-content h2 {
  text-align: center;
  font-size: 1.25rem;
  color: #a4abcb;
  font-weight: 400;
  line-height: 2rem;
  padding-bottom: 5rem;
}