
.icon,
.icon.blue {
  width: 2.0rem;
  height: 2.0rem;
  padding: .5rem;
  border-radius: 50%;
  background-color: #e9eefa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  color: #0a41c5;
  fill: #0a41c5;
}

.icon.white {
  background-color: #0a41c5;
}

.icon.white svg {
  color: #fff;
  fill: #fff;
}