.main {
  padding: 30px;
  background: #fff;
}

.main .intro {
  text-align: center;
  margin-bottom: 30px;
}

.main .intro h2 {
  color: #0c2074;
  font-size: 2.5rem;
  text-align: center;
  margin: 1.5rem auto 0;
}

dl.items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: none;
}

@media only screen and (min-width: 960px) {
  dl.items {
    width: 100%;
    max-width: 80%;
  }
}

dl.items .item {
  width: 100%;
  padding: 0 15px;
  margin: 0 -15px 30px;
}

@media only screen and (min-width: 960px) {
  dl.items .item {
    width: 50%;
  }
}