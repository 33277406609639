.site-footer {
  padding: 30px;
  color: white;
  position: relative;
}

.site-footer hr {
  width: 100%;
  height: 0;
  border: none;
  border-top: 1px solid #fff;
  margin: 20px 0 30px;
}