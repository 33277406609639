
.item {
  display: flex;
  flex-wrap: wrap;
}

.item dt {
  padding: 0 60px 0 0;
  margin: .5rem 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000;
}

.item dd {
  margin-left: 0;
  padding: 0 60px 0 0;
  line-height: 1.6;
}

.item .content {
  width: calc(100% - 5.0rem);
  padding: 0 0 0 2rem;
}